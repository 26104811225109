import {PropsWithChildren, useState} from 'react'
import {Link} from "@/Components/Catalyst/link";
import {Text} from "@/Components/Catalyst/text";
import RecipeVideoDialog from "@/Pages/Recipe/partials/RecipeVideoDialog";
import RecipeData = App.Data.RecipeData;

function FancyDivider() {
  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      aria-hidden="true"
      className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
    >
      <polygon points="0,0 90,0 50,100 0,100"/>
    </svg>
  )
}

export default function RecipeHero({recipe, children}: PropsWithChildren<{ recipe: RecipeData }>) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <div className="bg-white">
      <div className="relative">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">

            <FancyDivider/>

            <div className="relative px-6 sm:py-40 lg:px-8 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 ">
                {recipe.recipeUrl?.url &&
                  <div className="hidden sm:mb-10 sm:flex">
                    <div
                      className="relative rounded-full px-3 py-1 text-sm/6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                      {recipe.recipeUrl.label}
                      <a
                        href={recipe.recipeUrl.url}
                        className="whitespace-nowrap font-semibold text-emerald-600 ml-2"
                        target='_blank'
                      >
                        <span aria-hidden="true" className="absolute inset-0"/>
                        Visit recipe creator <span aria-hidden="true">&rarr;</span>
                      </a>
                    </div>
                  </div>}
                <h1 className="text-pretty text-7xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
                  {recipe.name}
                </h1>
                <Text className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                  {recipe.excerpt}
                </Text>
                <div className="mt-10 flex items-center gap-x-6">
                  <Link
                    href={route('recipe.view', {slug: recipe.slug})}
                    className="rounded-md bg-emerald-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                  >
                    Read full recipe
                  </Link>
                  {
                    recipe.recipeVideo?.url && (
                      <RecipeVideoDialog recipeVideo={recipe.recipeVideo}>
                        <Link
                          href={recipe.recipeVideo.url}
                          className="text-sm/6 font-semibold text-gray-900"
                        >
                          Watch video <span aria-hidden="true">→</span>
                        </Link>
                      </RecipeVideoDialog>
                    )
                  }
                </div>
              </div>
              {!!children && children}
            </div>
          </div>
        </div>
        <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            alt=""
            src={recipe.image?.filePath}
            className="aspect-[3/2] object-cover lg:aspect-auto lg:size-full"
          />
        </div>
      </div>
    </div>
  )
}
