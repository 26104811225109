import "react-multi-carousel/lib/styles.css";
import RecipeHero from "@/Components/Recipe/RecipeHero";
import {useState} from "react";
import RecipeData = App.Data.RecipeData;

interface Props {
  recipes: App.Data.RecipeData[]
}

export default function CardCarousel(props: Props) {
  const [currentRecipe, setCurrentRecipe] = useState<RecipeData>(props.recipes[0])
  return (
    <RecipeHero recipe={currentRecipe}>
      <div className='flex carousel gap-2 mt-10 lg:mt-30 mb-4'>
        {props.recipes
          .filter((recipe) => recipe.id !== currentRecipe.id)
          .map((recipe) => {
            // recipe image thumbnail with style
            return (
              <div className='carousel-item' key={recipe.uuid}>
                <img
                  src={recipe.image?.filePath}
                  onClick={() => setCurrentRecipe(recipe)}
                  className='
                    max-w-[200px] w-full
                    cursor-pointer
                    object-cover aspect-[16/9]
                    rounded-lg shadow-lg
                    opacity-50 hover:opacity-100
                    transition-opacity duration-300
                '
                />
              </div>
            )
          })}
      </div>
    </RecipeHero>
  );
}
